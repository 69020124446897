<template>
  <div class="department-card-navigation">
    <v-tabs background-color="transparent">
      <v-tab
          @click="scrolling('doctors')"
          class="search-tab-item"
          data-value="doctors"
      ><div class="doctor-icon icon"></div>
        Врачи
      </v-tab>
      <v-tab
          @click="scrolling('service')"
          class="search-tab-item"
          data-value="service"
      ><div class="service-icon icon"></div>
        Услуги
      </v-tab>
      <v-tab
          @click="scrolling('gallery')"
          class="search-tab-item"
          data-value="gallery"
      ><div class="gallery-icon icon"></div>
        Фото клиники
      </v-tab>
      <v-tab
          @click="scrolling('feedback')"
          class="search-tab-item"
          data-value="feedback"
      >
        <div class="feedback-icon icon"></div>
        Отзывы
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: "DepartmentCardNavigation",
  props: ["id", "name", "scroll"],
  methods:{
    scrolling(to){
      this.scroll(to)
    }
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
.doctor-icon {
  mask: url(~icons/doctor.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/doctor.svg) no-repeat center / contain;
}

.service-icon {
  mask: url(~icons/service.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/service.svg) no-repeat center / contain;
}

.feedback-icon {
  mask: url(~icons/feedback.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/feedback.svg) no-repeat center / contain;
}
.clinic-icon {
  mask: url(~icons/clinic.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/clinic.svg) no-repeat center / contain;
}
.gallery-icon {
  mask: url(~icons/gallery.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/gallery.svg) no-repeat center / contain;
}
.search-tab-item {
  text-transform: none;
  background: rgba(#e6e4e4, 0.5);
  cursor: pointer;
  text-align: center;
  font-family: $sanspro, sans-serif;
  font-style: normal;
  font-weight: normal;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  color: #000000 !important;
  padding: 10px 5px;
  margin-right: 2px;
  max-width: 150px;
  letter-spacing: normal;
  @include phone-only {
    width: 32%;
    height: auto;
  }

  .icon {
    pointer-events: none;
    width: 20px;
    height: 13px;
    background-color: #0a0a0a;
  }
}
.v-tab--active {
  color: #0044cc !important;
  background: #e3ecfc !important;
  font-weight: 400 !important;
  .icon {
    background-color: $deep-blue;
  }
}
</style>
