<template v-slot>
  <div class="department-card-doctors mb-10" ref="doctors">
    <v-container fluid>
        <v-row align="center">
          <v-col class="d-flex" cols="12">
            <v-select
                item-text="specialization"
                item-value="specializationId"
                :items="filterArr(objSpecializations)"
                v-model="selectedSpecialization"
                label="Выберите специализацию"
                outlined
                flat
                @input="dataFilter"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="objDepartmentDoctors.length">
          <v-col
              cols="12"
              md="4"
              sm="6"
              v-for="(data, index) in objDepartmentDoctors"
              :key="index"
          >
            <v-lazy
                v-model="isActive"
                :options="{
                 threshold: .5
                }"
                min-height="200"
                transition="fade-transition"
            >
              <v-card class="media" hover outlined>
              <div class="media-body d-flex align-content-end">
                <v-row>
                  <v-col cols="12" sm="12" class="text-center">
                    <v-avatar class="profile" color="grey" size="104" tile>
                      <v-img
                          v-if="data.avatar"
                          :src="'https://old.medveddoc.com' + data.avatar"
                          :alt="data.name + ', ' + data.specializationsString.join(', ') + ', Волгоград'"
                      ></v-img>

                      <v-img v-else src="~images/base_img_doctor.webp"></v-img>
                    </v-avatar>
                    <!--                  <div>-->
                    <!--                    <div class="display-m-none">-->
                    <!--                      <rating-component :rating="data.userRating" :category = "data.doctorCategory"-->
                    <!--                                                                                :degree="data.academicDegree"> </rating-component>-->
                    <!--                    </div>-->
                    <!--                  </div>-->
                  </v-col>
                  <v-col cols="12" sm="12" class="d-flex flex-column text-center align-content-stretch">
                    <router-link
                        :to="{
                      path: '/doctor/' + data.doctorUrl + '/' + data.userId,
                      params: { userId: data.userId, userName: data.doctorUrl },
                    }"
                        target="_blank"
                    >
                      <h5 class="media-heading" itemprop="description">
                        {{ data.name }}
                      </h5>
                    </router-link>
                    <span class="doctor-specialization">
                    {{ data.specializationsString.join(", ") }}
                  </span>
                    <span class="doctor-degree">
                    {{fullCategory(data.doctorCategory, data.academicDegree)}}
                  </span>
                  </v-col>
                  <v-col
                      sm="12"
                      class="btn-container d-flex justify-space-around align-self-end"
                  >
                    <v-btn
                        outlined
                        class="btn-detail mr-2 mb-0 mb-sm-2 mr-sm-0"
                        color="primary"
                        @click="
                      openDrawerDetail(
                        data.userId,
                        data.name,
                        data.specialization,
                        data.avatar
                      )
                    "
                    >
                      Подробнее
                    </v-btn>

                    <v-btn
                        outlined
                        class="btn-order"
                        color="success"
                        v-if="data.hiddenLk == 0"
                        @click="
                      openDrawerFast(
                        data.userId,
                        data.name,
                        data.specialization,
                        data.avatar
                      )
                    "
                    >
                      Записаться
                    </v-btn>
                    <v-btn
                        outlined
                        color="red"
                        style="font-size: 10px"
                        v-if="data.hiddenLk == 1"
                    >
                      Запись ограничена
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            </v-lazy>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <p>К сожаланию, данные отсутствуют.</p>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
import { mdiMenuLeft, mdiMenuRight } from "@mdi/js";
import * as names from "store/names";
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "lodash";

export default {
  name: "DepartmentCardDoctors",
  data() {
    return {
      selectedSpecialization: null,
      loading: false,
      page: 1,
      icons: {
        mdiMenuRight,
        mdiMenuLeft,
      },
      respData: {
        id: 1,
        entity: "card",
      },
    };
  },
  computed: {
    apiAddress() {
      return names.API_ADDRESS;
    },

    filterArr() {
      return function (arr) {
        let res;
        let first = [{ specialization: "Все", specializationId: "0" }];
        res = first.concat(arr);
        return res;
      };
    },
    fullCategory() {
      return function (category, degree) {
        let fullCat = "";
        if (category && !degree) {
          fullCat = category;
        }
        if (category && degree) {
          fullCat = category + ", " + degree;
        }
        if (!category && degree) {
          fullCat = degree;
        }

        return fullCat;
      };
    },

    ...mapGetters({
      depId: names.DEPARTMENT_ID_CARD,
      objDepartmentDoctors: names.DEPARTMENT_DOCTORS,
      objSpecializations: names.DEPARTMENT_DOCTORS_SPECIALIZATIONS,
      objDepartment: names.OBJ_DEPARTMENT_INFO,
    }),

    showResult() {
      return !isEmpty(this.objDepartmentDoctors);
    },
  },

  created() {
    this.getService(null);
    this.getSpecialization();
  },
  methods: {
    ...mapActions({
      getDepartmentDoctors: names.ACTION_GET_DEPARTMENT_DOCTORS,
      selectResultTemplate: names.ACTION_SELECT_RESULT_TEMPLATE,
      changeEntityId: names.ACTION_TRANSMISSION_ENTITY_ID,
      selectedDoctorInfo: names.ACTION_SAVE_DEPARTMENT_SELECTED_DOCTOR_INFO,
      getSpecializationsWithDoctors:
      names.ACTION_GET_DEPARTMENT_DOCTORS_SPECIALIZATIONS,
    }),
    scrollTo(){
      this.$refs.doctors.scrollIntoView({behavior: 'smooth'})
    },
    async getResult() {
      await this.selectResultTemplate({
        templateName: `DepartmentCardDoctors`,
        data: this.respData,
      });
    },

    getService(specializationId) {
      this.getDepartmentDoctors({
        departmentId: this.depId,
        specializationId: specializationId,
      });
    },
    getSpecialization() {
      this.getSpecializationsWithDoctors({
        departmentId: this.depId,
      });
    },

    dataFilter() {
      if (this.selectedSpecialization == 0) {
        this.getService(null);
      }
      if (this.selectedSpecialization !== 0) {
        this.getService(this.selectedSpecialization);
      }
    },

    openDrawerFast(doctorId, doctorName, doctorSpecialization, doctorAvatar) {
      this.getResult();
      document.querySelector("body").style.overflow = "hidden";
      this.selectedDoctorInfo({
        doctorId: doctorId,
        doctorName: doctorName,
        doctorSpecialization: doctorSpecialization,
        doctorAvatar: doctorAvatar,
        serviceName: null,
        serviceId: null,
        servicePrice: null,
        doctorServicePrice: null,
      });
      this.$drawer.showDrawer(true, "Fast");
    },
    openDrawerDetail(doctorId, doctorName, doctorSpecialization, doctorAvatar) {
      this.getResult();
      this.selectedDoctorInfo({
        doctorId: doctorId,
        doctorName: doctorName,
        doctorSpecialization: doctorSpecialization,
        doctorAvatar: doctorAvatar,
        serviceName: null,
        serviceId: null,
        servicePrice: null,
        doctorServicePrice: null,
      });
      this.$drawer.showDrawer(true, "Detail");
      document.querySelector("body").style.overflow = "hidden";
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";

.media {
  text-align: left;
  margin-top: 15px;
  overflow: visible;
  padding: 10px;
  height: 100%;
  border: 1px solid #f2f2f2;
  cursor: default;

  &:hover {
    box-shadow: 0 2px 6px 5px #e3ecfc;
  }

  a {
    text-decoration: none;
  }
  &-body{
    height: 100%;
  }

  &-heading {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #262626;
    padding-bottom: 5px;
    cursor: pointer;

    &:hover {
      color: #0044cc;
    }
  }

  .doctor-specialization {
    font-size: 14px;
    align-self: center;
    margin: auto;
    text-align: center;

  }

  .doctor-degree {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #000000;
    align-self: center;
    margin: auto;
    text-align: center;
  }

  .btn-container {
    @include smaller-phone {
      //margin-top: 20px;
    }

    button {
      width: 104px;
      height: 35px;
      font-size: 13px;
      background: initial;
      text-transform: none;
      @include phone-only {
        width: 100px;
        height: 35px;
      }
    }
  }
}
</style>
