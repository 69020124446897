<template v-slot>
  <div ref="feedback">
    <CardFeedback :departmentId="depId" :doctorId="null" type="clinic" />
  </div>
</template>

<script>
import CardFeedback from "components/card-feedback/CardFeedback";
import { mapGetters } from "vuex";
import * as names from "store/names";

export default {
  name: "DepartmentCardFeedback",
  components: { CardFeedback },
  data: () => ({}),
  computed: {
    ...mapGetters({
      depId: names.DEPARTMENT_ID_CARD,
    }),
  },
  methods: {
    scrollTo(){
      this.$refs.feedback.scrollIntoView({behavior: 'smooth'})
    },
  },
};
</script>

<style scoped></style>
