<template>
  <div class="department-card">
    <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            :text="newText()"

        > {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <department-card-navigation :id="this.id" :name="this.name" :scroll="scroll"/>
    <div class="department-card-wrapper">
      <department-card-info></department-card-info>
      <department-card-doctors ref="doctors"/>
      <department-card-service  ref="service"/>
      <department-card-gallery ref="gallery"/>
      <department-card-feedback  ref="feedback"/>
      <button-back-to-top></button-back-to-top>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import DepartmentCardNavigation from "components/department-card/DepartmentCardNavigation";
import DepartmentCardInfo from "components/department-card/DepartmentCardInfo";
import DepartmentCardService from "components/department-card/templates/DepartmentCardService";
import DepartmentCardDoctors from "components/department-card/templates/DepartmentCardDoctors";
import DepartmentCardGallery from "components/department-card/templates/DepartmentCardGallery";
import DepartmentCardFeedback from "components/department-card/templates/DepartmentCardFeedback";
import ButtonBackToTop from "components/partials/ButtonBackToTop";
import {mapActions, mapGetters} from "vuex";
import * as names from "store/names";

export default {
  name: "DepartmentCardComponent",
  props: ["id", "name"],
  components: { DepartmentCardNavigation, DepartmentCardInfo, ButtonBackToTop,  DepartmentCardService, DepartmentCardDoctors, DepartmentCardGallery, DepartmentCardFeedback},
  data() {
    return {
      items: [
        {
          text: 'главная',
          disabled: false,
          to: '/',
        },
        {
          text: '',
          disabled: true,
          to: '/department/:id/:name',
        }]
    }
  },
  metaInfo(){
    return {
      title: `${this.objDepartmentInfo.name }`,
      titleTemplate: "%s ← Medveddoc",

      meta: [
        {
          name: "description",
          content:`${this.objDepartmentInfo.name + ', Волгоград' }`,
        },
      ],
    }
  },

  created() {
    this.saveDepId(this.id);
  },
  computed: {
    ...mapGetters({
      objDepartmentInfo: names.OBJ_DEPARTMENT_INFO,
    }),
  },
    methods: {
    ...mapActions({
      saveDepId: names.ACTION_SAVE_DEPARTMENT_ID_CARD,
    }),
    newText(){
      this.items[1].text = this.objDepartmentInfo.name
    },
    scroll(to){
      this.$refs.[to].scrollTo()
    }
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
.v-breadcrumbs {
  padding: 18px 0px ;
}
.department-card {
  font-size: 14px;
  line-height: 17px;
  max-width: 930px;
  margin: 40px auto 60px;

  .department-card-wrapper {
    border: 10px solid #e3ecfc;
    font-family: $sanspro;
  }
}
</style>
