<template>
  <v-card flat class="department-card-info">
    <v-row>
      <v-col cols="12" sm="4" class="d-flex justify-center justify-sm-start">
        <v-avatar class="profile" color="grey" size="150" tile>
          <v-img
            v-if="objDepartment.avatar"
            :src="apiAddress + objDepartment.avatar"
            :alt="objDepartment.name + ', Волгоград'"
          ></v-img>
          <v-img v-else src="~images/base_img_doctor.webp"></v-img>
        </v-avatar>
      </v-col>
      <v-col cols="12" sm="8">
        <v-row class="card-info">
          <v-col cols="12">
            <h1 class="card-info__name">{{ objDepartment.name }}</h1>
          </v-col>
          <v-col cols="12" sm="5">
            <div class="card-info__schedule">
              <span>Время работы:</span>
              <div v-for="day in objDepartment.calendar" :key="day.day">
                <div v-if="!day.isDayOff">
                  {{ worksDays(day.day) }} : {{ day.start }}-{{ day.end }}
                </div>

                <div v-else>{{ day.day }} : {{ day.start }}</div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="7">
            <div class="card-info__address">
              <span>Адрес:</span>
              <div>{{ objDepartment.city }},</div>

              <div>{{ objDepartment.address[2] }}</div>

              <div class="map-link">
                <v-btn outlined small color="blue lighten-2" @click="showMap">
                  <v-icon class="mb-0 mr-1" color="blue lighten-2" small>{{ icons.mdiMapMarker }}</v-icon>
                  Показать на карте
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="5">
            <v-expansion-panels flat v-if="objClinicDepartments.length > 1">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Клиника входит в сеть клиник {{ objDepartment.clinic_name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <v-list-item
                      v-for="(item, key) in objClinicDepartments"
                      :key="key"
                    >
                      <router-link
                        :to="{
                          path: '/department/' + item.url + '/' + item.id,
                          params: { depId: item.id, depName: item.url },
                        }"
                        target="_blank"
                      >
                        <span :class="b('department')"> {{ item.name }}</span>
                      </router-link>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" sm="7" class="text-sm-start text-center">
            <v-btn
              outlined
              color="success"
              class="btn-curtain-open"
              @click="openDrawerFast()"
            >
              Записаться на прием
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <map-modal
      :address="objDepartment.city + ', ' + objDepartment.address[2]"
      :coordinates="newCoordinates()"
      :title="objDepartment.name"
      v-if="addressMap"
    />
  </v-card>
</template>

<script>
import * as names from "store/names";
import { mapActions, mapGetters } from "vuex";
import MapModal from "components/partials/MapModal";
import { mdiMapMarker } from "@mdi/js";

export default {
  name: "DepartmentCardInfo",
  components: { MapModal },
  data: () => ({
    respData: {
      id: 1,
      entity: "card",
    },
    icons: {
      mdiMapMarker,
    },
  }),
  computed: {
    worksDays() {
      return function (daysArr) {
        let fullWorksDays = daysArr.split(",");
        let worksDays = "";
        if (fullWorksDays.length > 1) {
          worksDays =
            fullWorksDays[0] + " - " + fullWorksDays[fullWorksDays.length - 1];
        } else {
          worksDays = fullWorksDays[0];
        }
        return worksDays;
      };
    },
    apiAddress() {
      return names.API_ADDRESS;
    },
    ...mapGetters({
      objDepartment: names.OBJ_DEPARTMENT_INFO,
      depId: names.DEPARTMENT_ID_CARD,
      addressMap: names.ADDRESS_DIALOG,
      objClinicDepartments: names.OBJ_CLINIC_DEPARTMENTS,
    }),
  },
  created() {
    this.getInfo();
  },

  methods: {
    ...mapActions({
      selectResultTemplate: names.ACTION_SELECT_RESULT_TEMPLATE,
      getDepartment: names.ACTION_MAIN_SEARCH_CLINIC_DETAIL,
      openDialog: names.ACTION_SAVE_ADDRESS_DIALOG,
      getClinicDepartments: names.ACTION_SAVE_OBJ_CLINIC_DEPARTMENTS,
    }),

    newCoordinates(){
     return new Array(this.objDepartment.address)
    },

    async getResult() {
      await this.selectResultTemplate({
        templateName: `DepartmentCard`,
        data: this.respData,
      });
    },

    showMap() {
      this.openDialog(true);
    },

    openDrawerFast() {
      this.getResult();
      document.querySelector("body").style.overflow = "hidden";
      this.$drawer.showDrawer(true, "Fast");
    },
    async getInfo() {
      let response = await this.getDepartment({ departmentId: this.depId });
      if (response === "success") {
        this.getClinicDepartments({ clinicId: this.objDepartment.clinic_id });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
.v-expansion-panel-header {
  padding: 5px;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 20px;
}

.btn-curtain-open {
  width: 220px;
  height: 40px;
  font-size: 16px;
  letter-spacing: 0.04em;
  text-transform: none;
}

.department-card-info {
  min-height: 300px;
  padding: 20px;

  .card-info {
    &__image {
      max-width: 150px;
      height: 150px;
      overflow-y: hidden;

      img {
        width: 100%;
        height: auto;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }

    &__name {
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 29px;
      width: 100%;
      margin-bottom: 12px;
      @include phone-only {
        margin-top: 20px;
      }
    }

    &__open-in {
      display: inline-block;
      margin-top: 15px;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $primary-grey-color;
    }

    &__map {
      margin-top: 25px;
      height: 600px;
      background-color: rgba(107, 135, 153, 0.3);
      border: 1px solid rgba(107, 135, 153, 0.3);
    }

    &__address,
    &__schedule,
    &__phone {
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;

      color: $primary-dark-grey-color;

      span {
        margin-bottom: 10px;
        display: inline-block;
        width: 100%;
        color: rgba($primary-grey-color, 0.7);
      }

      i {
        color: red;
        font-size: 18px;
      }

      .map-link {
        margin-top: 10px;

        small {
          color: #006ddb;
        }

        &__btn {
          border: none;
          background: inherit;
        }
      }
    }
  }
}

@include phone-only {
  .department-card__info {
    margin: 0px;
    padding: 10px;
  }
  .department-card__map {
    height: 500px;
  }
}

@include tablet-down {
  .department-card__favorite,
  .department-card__subscribe {
    display: block;
  }
  .department-card__fav-sub {
    position: unset;
    margin-top: 10px;
  }
}

@include desktop-down {
  .department-card-info__image {
    margin: 5px;

    img {
      height: 210px;
    }
  }
}
</style>
